@font-face {
  font-family: "renogareSoft";
  src: local("MyFont"), url(./fonts/RenogareSoft-Regular.ttf) format("woff");
}

.st0 {
  fill: white !important;
}

.main-background {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  width: 100vw;
  background: url("./svg/logo.svg") no-repeat center;
  background-size: 90vw;
  background-color: #333333;
}
